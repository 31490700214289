var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',{attrs:{"loading":_vm.loading}},[(_vm.htmlPreview)?_c('iframe',{staticStyle:{"width":"0px","height":"0px","position":"absolute","z-index":"-1","border":"0px"},attrs:{"srcdoc":_vm.htmlPreview},on:{"load":_vm.getScreenshot}}):_vm._e(),_c('VsSectionHeader',{staticClass:"vs-mb-8",attrs:{"truncate":"","heading":"Dettaglio modello email"},scopedSlots:_vm._u([{key:"preActions",fn:function(){return [_c('VsButton',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"variant":"icon","iconName":"chevron-left","aspect":"secondary","size":"large","title":"I miei modelli email","to":{
                    name: _vm.isManagerRoute ? 'managerEmailEditorIndex' : 'emailEditorIndex'
                }}})]},proxy:true},{key:"actions",fn:function(){return [_c('VsButton',{staticClass:"vs-flex-auto",attrs:{"aspect":"secondary","size":"large"},on:{"click":function($event){return _vm.openDrago(_vm.template)}}},[_vm._v(" Modifica contenuto ")]),_c('VsDropdownButton',[_c('VsActionItem',{attrs:{"label":"Anteprima"},on:{"click":_vm.openPreview}}),_c('VsActionItem',{attrs:{"label":"Duplica"},on:{"click":_vm.duplicateTheme}}),(!_vm.canExportHtml)?_c('VsActionItem',{attrs:{"label":"Esporta html","appendIconName":"lock","disabled":""},on:{"click":function($event){return _vm.$root.$vsOpenBlockedModal()}}}):_vm._e(),(_vm.canExportHtml)?_c('VsActionItem',{attrs:{"label":"Esporta html"},on:{"click":_vm.exportAsHtml}}):_vm._e(),_c('VsActionItem',{attrs:{"aspect":"alert","label":"Elimina"},on:{"click":_vm.deleteThemeById}})],1)]},proxy:true}])}),_c('VsSidebarLayout',{scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [(_vm.template)?_c('VsCard',{attrs:{"rounded":"small"}},[_c('VsImage',{attrs:{"src":_vm.previewImage,"enableHover":true,"backgroundPosition":"center"}},[_c('VsButton',{attrs:{"variant":"icon","aspect":"ghost","iconName":"zoom-in","size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.openPreview.apply(null, arguments)}}})],1)],1):_vm._e()]},proxy:true}])},[(_vm.template)?_c('div',[_c('ValidationObserver',{ref:"templateData"},[_c('ValidationProvider',{attrs:{"name":"Titolo","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-5",attrs:{"label":"Titolo","size":"large","placeholder":"Inserisci un titolo","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.template.title),callback:function ($$v) {_vm.$set(_vm.template, "title", $$v)},expression:"template.title"}})]}}],null,false,234714556)}),_c('ValidationProvider',{attrs:{"name":"Descrizione","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsTextArea',{staticClass:"vs-mb-5",attrs:{"label":"Descrizione","size":"large","placeholder":"Inserisci la tua descrizione","error":errors.length > 0,"errorMessage":errors[0],"rows":3,"resize":""},model:{value:(_vm.template.description),callback:function ($$v) {_vm.$set(_vm.template, "description", $$v)},expression:"template.description"}})]}}],null,false,771174248)}),_c('VsSelect',{staticClass:"vs-mb-5",attrs:{"value":_vm.template.entity_labels.map(function (el) { return el.id; }),"variant":"multiSelection","options":_vm.entityLabels.map(function (el) {
                        return {
                            label: el.name,
                            value: el.id,
                        }
                    }),"filterable":"","label":"Tag","size":"large","placeholder":"Assegna un tag"},on:{"input":_vm.replaceTags}}),_c('VsButton',{attrs:{"size":"large","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.updateThemeById}},[_vm._v(" Salva ")])],1)],1):_vm._e(),_c('VsDragoModal',{ref:"vsDragoModal",attrs:{"isTemplate":""},on:{"content-saved":_vm.contentSaved}}),_c('VsPreviewModal',{ref:"previewModal"}),_c('VsConfirm',{ref:"deleteTemplateConfirm",attrs:{"closeOnClickOverlay":false,"confirmButton":_vm.$t('emailEditor.confirmDeleteTemplate.confirmButton'),"closeButton":_vm.$t('general.cancel')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('emailEditor.confirmDeleteTemplate.subtitle', 1))+" ")]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$tc('emailEditor.confirmDeleteTemplate.title', 1))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }